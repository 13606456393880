<template>
  <v-app class="transparent">
    <v-container
      id="regular-tables"
      fluid
      tag="section"
    >
      <div class="text-center vertically-centered" v-if="page_loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-expansion-panels v-model="panel" multiple v-else>
        <v-expansion-panel>
          <v-expansion-panel-header class="display-2">Patient General Info</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="form" class="mt-4">
              <v-container class="py-0">
                <v-row>
                  <v-col
                    cols="12"
                    md="4">
                    <v-text-field
                      label="Patient Name"
                      placeholder="Patient Name"
                      disabled
                      :value="phr.name"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4">
                    <v-text-field
                      label="Gender"
                      placeholder="Gender"
                      disabled
                      :value="phr.gender"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4">
                    <v-text-field
                      label="National ID"
                      placeholder="National ID"
                      disabled
                      :value="phr.national_no"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4">
                    <v-text-field
                      label="Weight"
                      placeholder="Weight"
                      disabled
                      :value="phr.weight"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4">
                    <v-text-field
                      label="Date of Birth"
                      placeholder="Date of Birth"
                      disabled
                      :value="phr.date_of_birth"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4">
                    <v-text-field
                      label="Age"
                      placeholder="Age"
                      disabled
                      :value="phr.date_of_birth | ageFromDate"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="phr.having_allergy === 'yes'">
          <v-expansion-panel-header class="display-2">Allergies</v-expansion-panel-header>
          <v-expansion-panel-content>
            <template v-for="allergy in phr.phrAllergies">
              <v-chip
                v-if="allergy.term && allergy.term.icd10 && allergy.term.icd10.name_en"
                class="ma-2 title"
                color="cyan"
                text-color="white">
                ({{ allergy.term.icd10.icd_10_code }})
                {{ allergy.term.icd10.name_en }}
              </v-chip>
              <v-chip
                v-else
                class="ma-2 title"
                color="cyan"
                text-color="white">
                {{ allergy.allergy_name }}
              </v-chip>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="consultation.icd10Diagnosis">
          <v-expansion-panel-header class="display-2">Diagnosis</v-expansion-panel-header>
          <v-expansion-panel-content>
            <template v-for="diagnose in consultation.icd10Diagnosis">
              <v-chip
                class="ma-2 title"
                color="cyan"
                text-color="white">
                ({{ diagnose.icd_10_code }})
                {{ diagnose.name_en }}
              </v-chip>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="display-2">FDA Drugs</v-expansion-panel-header>
          <v-expansion-panel-content>
            <template v-for="(consultationFdaDrug, index) in consultation.consultationFdaDrugs">
              <div>
                <v-alert
                  color="cyan"
                  border="left"
                  elevation="2"
                  colored-border>
                    <v-row>
                      <v-col cols="6" md="6">
                        <v-checkbox
                          class="mt-0"
                          v-model="tempDispenseForm[index].is_dispensed"
                          :label="consultationFdaDrug.fdaDrug.generic_name"
                          color="cyan"
                          :false-value="0"
                          :true-value="1"
                          @change="drugChecked(index)"
                          :disabled="!!dispenseForm[index].is_dispensed || !!dispenseForm[index].alternative_fda_drug_id"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" md="6" v-if="!consultationFdaDrug.alternative_fda_drug_id">
                        <v-autocomplete
                          v-model="tempDispenseForm[index].alternative_fda_drug_id"
                          :items="fdaDrugList"
                          item-text="trade_name"
                          item-value="id"
                          @keydown="getFdaDrugList"
                          dense
                          small-chips
                          label="Dispense alternative drug"
                          color="cyan"
                          outlined
                          rounded
                          @change="alternativeChecked(index)"
                          :disabled="!!dispenseForm[index].is_dispensed || !!dispenseForm[index].alternative_fda_drug_id"
                          :filled="!!dispenseForm[index].is_dispensed || !!dispenseForm[index].alternative_fda_drug_id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6" md="6" v-if="consultationFdaDrug.alternativeFdaDrug">
                        <v-select
                          dense
                          small-chips
                          :label="consultationFdaDrug.alternativeFdaDrug.trade_name"
                          color="cyan"
                          outlined
                          rounded
                          :disabled="true"
                          :filled="true"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-chip
                          v-if="consultationFdaDrug.frequency"
                          class="ma-2"
                          color="cyan"
                          text-color="white"
                        >
                          Frequency: ({{ consultationFdaDrug.frequency }})
                        </v-chip>
                        <v-chip
                          v-if="consultationFdaDrug.dosage"
                          class="ma-2"
                          color="cyan"
                          text-color="white"
                        >
                          Dosage: ({{ consultationFdaDrug.dosage }})
                        </v-chip>
                        <v-chip
                          v-if="consultationFdaDrug.duration"
                          class="ma-2"
                          color="cyan"
                          text-color="white"
                        >
                          Duration: ({{ consultationFdaDrug.duration }})
                        </v-chip>
                        <v-chip
                          v-if="consultationFdaDrug.alternativeFdaDrug && consultationFdaDrug.alternativeFdaDrug.registration_number"
                          class="ma-2"
                          color="cyan"
                          text-color="white"
                        >
                          Registration Number: ({{ consultationFdaDrug.alternativeFdaDrug.registration_number }})
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          color="cyan"
                          text-color="white"
                        >
                          Registration Number: ({{ consultationFdaDrug.fdaDrug.registration_number }})
                        </v-chip>
                      </v-col>
                    </v-row>
                  <div
                    class="float-right font-weight-bold grey--text text--darken-1"
                    v-if="!!dispenseForm[index].is_dispensed || !!dispenseForm[index].alternative_fda_drug_id">
                    <v-icon
                      large
                      color="green">
                      mdi-check-circle
                    </v-icon>
                    Dispensed
                  </div>
                </v-alert>
              </div>
            </template>
            <v-col
              cols="12"
              class="text-right">
              <v-btn
                v-if="!consultation.branch_id || ($auth.getCurrentUser().branch_id === consultation.branch_id)"
                :loading="loading"
                :disabled="loading || (consultation.recommendationFlag && consultation.recommendationFlag.dispense_status === 'fully_dispensed')"
                color="success"
                class="mr-0 font-weight-bold"
                rounded
                @click="dispense">
                <template v-if="consultation.recommendationFlag && consultation.recommendationFlag.dispense_status === 'fully_dispensed'">
                  Dispensed
                </template>
                <template v-else>
                  Dispense
                </template>
              </v-btn>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "Dispense",
  created() {
    this.getConsultation();
    // this.getFdaDrugList();
  },
  computed: {
    items () {
      return this.fdaDrugList.map(fdaDrug => {
        const TradeName = fdaDrug.trade_name;
        return Object.assign({}, fdaDrug, { TradeName })
      })
    }
  },
  data() {
    return {
      page_loading: true,
      loading: false,
      panel: [0, 1, 2, 3],
      consultation_id: this.$route.params.id,
      consultation: {},
      consultationFdaDrugs: [],
      fdaDrugList: [],
      phr: {},
      dispenseForm: [],
      tempDispenseForm: [],
      search: null
    }
  },
  watch: {
    search (val) {
      if (val && val.length < 3) {
        return;
      }
      // Items have already been loaded
      if (this.items.length > 0) return
      // Lazily load input items
      this.$authApi.get('fda-drugs').then(res => {
        this.fdaDrugList = res.data.data.fdaDrugs;
      })
    }
  },
  methods: {
    getConsultation () {
      this.$authApi.get(`consultations/consultation-drugs/${this.consultation_id}`).then(res => {
        this.consultation = res.data.data;
        this.consultationFdaDrugs = this.consultation.consultationFdaDrugs;
        this.setPhr();
        this.prepareDispenseForm();
        this.page_loading = false;
      }).catch(error => {
        this.page_loading = false;
        this.flashMessage.error({
          title: 'Opps!',
          message: 'Something went wrong, please try later',
          position: 'right top'
        });
      });
    },
    setPhr() {
      this.phr = this.consultation.phr ? this.consultation.phr : this.consultation.member.myPhr;
    },
    prepareDispenseForm() {
      let _this = this;
      this.consultationFdaDrugs.forEach(function (consultationFdaDrug) {
        _this.dispenseForm.push(
          {
            tbi_consultation_fda_drug_id: consultationFdaDrug.tbi_consultation_fda_drug_id,
            is_dispensed: consultationFdaDrug.is_dispensed,
            alternative_fda_drug_id: consultationFdaDrug.alternative_fda_drug_id,
            branch_id: _this.$auth.getCurrentUser().branch_id
          }
        );
        _this.tempDispenseForm.push(
          {
            tbi_consultation_fda_drug_id: consultationFdaDrug.tbi_consultation_fda_drug_id,
            is_dispensed: consultationFdaDrug.is_dispensed,
            alternative_fda_drug_id: consultationFdaDrug.alternative_fda_drug_id,
            branch_id: _this.$auth.getCurrentUser().branch_id
          }
        );
      });
    },
    getFdaDrugList(e) {
      let keyword = e.target.value;
      // if (keyword.length < 3) {
      //   this.fdaDrugList = [];
      //   return;
      // }
      this.$authApi.get('fda-drugs', {
        params: {
          trade_name: keyword
        }
      }).then(res => {
        this.fdaDrugList = res.data.data.fdaDrugs;
      })
    },
    drugChecked(index) {
      if (this.tempDispenseForm[index].is_dispensed) {
        this.tempDispenseForm[index].alternative_fda_drug_id = null;
      }
    },
    alternativeChecked(index) {
      if (this.tempDispenseForm[index].alternative_fda_drug_id) {
        this.tempDispenseForm[index].is_dispensed = 0;
      }
    },
    dispense() {
      this.loading = true;
      this.dispenseForm = this.tempDispenseForm;
      this.$authApi.post(`consultations/${this.consultation_id}/dispense`, this.dispenseForm).then(res => {
        this.getConsultation();
        this.loading = false;
        this.flashMessage.success({
          title: 'Success!',
          message: `Prescription dispensed successfully`,
          position: 'right top'
        });
        this.$router.push('/consultation-drug-delivery');
      }).catch(error => {
        this.loading = false;
        this.flashMessage.error({
          title: 'Opps!',
          message: 'Something went wrong, please try later',
          position: 'right top'
        });
      })
    },
  }
}
</script>

<style scoped>
._vue-flash-msg-body._vue-flash-msg-body_success {
  z-index: 100 !important;
}
</style>
